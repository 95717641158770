import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoginService } from './login.service';

import { User } from '../structures/user';
import { Application } from '../structures/application';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        private http: HttpClient,
        private loginService: LoginService
    ) {}

    @log() getUsers(params: any): Observable<any> {
        return this.http.get(`/users`, { params });
    }

    @log() getUsersLight(params: any): Observable<any> {
        return this.http.get(`/users/light`, { params });
    }

    @log() getUser(userId: number): Observable<any> {
        return this.http.get(`/users/${userId}`);
    }

    @log() getTutorUsers(userId: number): Observable<any> {
        return this.http.get(`/users/${userId}/tutor`);
    }

    @log() getTags(): Observable<any> {
        return this.http.get(`/tags/search`);
    }

    @log() resetTempPassword(userId: number): Observable<any> {
        return this.http.put(`/users/${userId}/reset_passwordtemp`, undefined);
    }

    @log() resendValidationMail(userId: number): Observable<any> {
        return this.http.post(`/users/${userId}/re_send_mail_validation`, {});
    }

    @log() getApplicationAccess(structureId: number, userId: number): Observable<any> {
        if (!structureId) {
            structureId = 0;
        }
        const params: any = {};
        if (userId) {
            params.with_users = 1;
            params.with_user_id = userId;
        }

        return this.http.get(`/applications/access/${structureId}`, { params });
    }

    @log() createUser(user: User): Observable<any> {
        return this.http.post(`/users`, user);
    }

    @log() updateUser(user: User): Observable<any> {
        return this.http.put(`/users/${user.id}?events=true`, user);
    }

    @log() addUserToTutor(userId: number, tutorId: number): Observable<any> {
        return this.http.post(`/users/${userId}/tutor/${tutorId}`, undefined);
    }

    @log() deleteUserFromTutor(userId: number, tutorId: number): Observable<any> {
        return this.http.delete(`/users/${userId}/tutor/${tutorId}`);
    }

    @log() addAccessToApplication(user: User, application: Application) {
        const body = {
            structureid: user.structureid,
            type: 'user',
            instance: user.id,
            applicationid: application.id,
            allow: true
        };

        return this.http.post(`/applications/access/${user.structureid}`, body);
    }

    @log() removeAccessToApplication(user: User, application: Application) {
        const body = {
            structureid: user.structureid,
            type: 'user',
            instance: user.id,
            applicationid: application.id,
            allow: false
        };

        return this.http.post(`/applications/access/${user.structureid}`, body);
    }

    @log() resetAccessToApplication(user: User, application: Application) {
        const body = {
            structureid: user.structureid,
            type: 'user',
            instance: user.id,
            applicationid: application.id,
            allow: 'delete'
        };

        return this.http.post(`/applications/access/${user.structureid}`, body);
    }

    @log() getAdditionalRoleAccess(structureId: number): Observable<any> {
        if (!structureId) {
            structureId = 0;
        }
        return this.http.get(`/additional_roles/access/${structureId}`).pipe(
            map((data: any) => data.additional_roles),
            map((data: any) => this.loginService.addIconsToRoles(data))
        );
    }

    @log() addAdditionalRole(user: User, additionalRole: any) {
        const body = {
            structureid: user.structureid,
            type: 'user',
            instance: user.id,
            additional_role_id: additionalRole.id,
            allow: true
        };

        return this.http.post(`/additional_roles/access/${user.structureid}`, body);
    }

    @log() removeAdditionalRole(user: User, additionalRole: any) {
        const body = {
            structureid: user.structureid,
            type: 'user',
            instance: user.id,
            additional_role_id: additionalRole.id,
            allow: false
        };

        return this.http.post(`/additional_roles/access/${user.structureid}`, body);
    }

    @log() getUserHistory(userId: number, startDate: Date, endDate: Date) {
        startDate.setMilliseconds(0);
        endDate.setMilliseconds(0);
        const params: any = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
        };

        return this.http.get(`/users/${userId}/history`, { params });
    }

    @log() callUser(message: any) {
        return this.http.post(`/notifications/call`, message);
    }

    @log() deleteUser(userId: number): Observable<any> {
        return this.http.delete(`/users/${userId}`);
    }
}
